<template>

  <div class="
      border-0
      pt-5
      pb-5
      d-flex
      justify-content-between
      flex-column flex-xxl-row
      align-items-start align-items-xxl-center
    ">

    <div class="
        flex-column flex-xxl-row
        d-flex
        align-items-start align-items-xxl-center

        ms-xxl-0 ms-md-7 ms-9
      "
      style="margin-left: 0;"
      >

      <div class="me-9">
        <span class="
            svg-icon svg-icon-3 svg-icon-gray-500
            position-absolute
            translate-middle
            ps-10
            pt-11
          ">
          <span class="svg-icon svg-icon-2">
            <inline-svg src="media/icons/duotune/general/gen004.svg" />
          </span>
        </span>
        <input type="text" class="form-control form-control-sm form-control-solid ps-10"
          style="min-width: 150px; max-width: 150px;" v-model="searchInput"
          placeholder="Pesquisar..." />
      </div>

      <!--end::Menu-->
    </div>
  </div>


  <div class="table-responsive">
    <table class="table table-hover table-row-gray-500 gy-5 gs-5 mb-0">
      <thead id="table-head">
        <tr class="fw-bolder text-muted" style="white-space: nowrap">
          <th class="text-dark fs-7 fw-bolder white-text">Codigo</th>
          <th class="text-dark fs-7 fw-bolder white-text">Nome</th>
          <th class="text-dark fs-7 fw-bolder white-text">Selecionar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="client in filterClients" :key="client.id">
          <td class="text-dark fw-bolder fs-7">{{ client.numcl }}</td>
          <td class="fs.7">{{ client.nome }}</td>
          <td>
            <button @click="selectClient(client.numcl)" class="btn white-text">
              Selecionar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ClientsTable",
  props: {
    clients: Array,
  },
  data() {
    return {
      searchInput: null,
    };
  },
  emits: ["selectClient"],
  computed: {
    filterClients() {
      let filteredClients = []

      if (this.searchInput != '' && this.searchInput) {

        filteredClients = this.clients.filter((item) => {
          return item.nome.toLowerCase().includes(this.searchInput.toLowerCase())
              || (item.numcl + "").includes(this.searchInput)
        })

        return filteredClients
      } 
      return this.clients
    }

    

  },
methods: {
    selectClient(numcl) {
      this.$emit("selectClient", numcl);
    },
  },
};
</script>

<style scoped>
#table-head {
  background-color: #791b1d !important;
}

.btn {
  background-color: #791b1d !important;
}

.btn:hover {
  background-color: #d33e41 !important;
}

.white-text {
  color: white !important;
}
.table-responsive {
  max-height: 80vh;
}


</style>