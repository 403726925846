<template>
  <a href="#" class="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal" id="kt_client_pick_btn"
    ref="kt_client_pick_btn">
    Create
  </a>

  <div class="modal position-relative d-block" id="exampleModal" tabindex="-1" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <button ref="hiddenButtonToHideDialog" type="button" class="d-none" data-bs-dismiss="modal"></button>
      <div class="modal-content" id="#modalContent">
        <div class="modal-header">
          <h4 class="modal-title title-center">Selecionar cliente</h4>
        </div>
        <div class="modal-body pt-0">
          <ClientsTable :clients="listClients" @selectClient="selectClient">
          </ClientsTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import store from "@/store";
import { useRouter } from "vue-router";

import ClientsTable from "@/components/customTables/ClientsTable.vue";
import {Actions} from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "PickClient",
  components: {
    ClientsTable,
  },
  data() {
    return {
      errors: null,
      listClients: [],
    };
  },

  props: {

  },

  methods: {
    verifyClientType() {
      if (store.getters.currentAuthUser.numcl && store.getters.currentAuthUser.numcl != 0) {
        useRouter().push({ name: "dashboard" });
      }
      this.loadClients();
    },

    loadClients() {
      ApiService.get("/Vendedor/VendedorToCliente")
        .then((response) => {
          this.listClients = response.data;
          this.show();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectClient(client) {
      //close modal
      let btnToCloseModal = this.$refs.hiddenButtonToHideDialog
      btnToCloseModal.click()
      store.dispatch(Actions.LOGIN_AS_CLIENT, {
        codCliente: client
      }).then((response) => {
        if(response === 200)
          this.$router.push({name: "dashboard"});
        else
          useRouter.push({name: "sign-in"});
      });

    },

    show() {
      let btnToOpenModal = this.$refs.kt_client_pick_btn;
      btnToOpenModal.click();
    },
  },

  mounted() {
    this.verifyClientType();

  },
});
</script>

<style>
#noMarginModel {
  margin: 0;
}

/* .modal-content {
  width: 50vw;
} */

@media screen and (min-width: 500px) {
  .modal-content {
    width: 90vw;
  }
}
</style>